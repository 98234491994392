
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import LinkButton from "../components/LinkButton";

const NotFound = () => {
  return (
    <Layout>
      <div className="grid cols-1 space-y-12 justify-center items-center">
        <h1 className="font-extrabold text-gray-700 text-3xl mb-4 text-center">
          <span className="block text-5xl">Oops,</span> that page does not exist.
        </h1>
        <StaticImage src="../images/not-found.png" className="max-w-5xl" />
        <LinkButton to="/" text="Go to Homepage" />
      </div>
    </Layout>
  );
};

export default NotFound;
